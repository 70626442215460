import { gql } from '@apollo/client';
import {
  ImageMediaFragment,
  MediaFragment,
  SeriesTvSeasonsFragment,
  SourcesFragment,
  TracksFragment,
  RatingsFragment
} from './programs.fragments';

const ProgramsQuery: any = gql`
  query programs(
    $tvSeasonId: String
    $programTypes: [ProgramType]
    $seriesId: String
    $skip: Int
    $limit: Int
    $sort: ProgramSortKey
    $guid: [String]
  ) {
    programs(
      tvSeasonId: $tvSeasonId
      programTypes: $programTypes
      seriesId: $seriesId
      skip: $skip
      limit: $limit
      sort: $sort
      guid: $guid
    ) {
      totalResults
      items {
        availableRegion
        id
        guid
        slug
        tvSeasonId
        type
        title
        description
        added
        publicationDateTime
        longDescription
        shortDescription
        displayGenre
        tvSeasonEpisodeNumber
        seasonNumber
        seriesId
        duration
        series {
          id
          slug
          guid
          title
        }
        metadata
        ...ImageMedia
        ...Media
        ...SeriesTvSeasons
        ...Sources
        ...Tracks
        ...Ratings
      }
    }
  }
  ${ImageMediaFragment}
  ${SeriesTvSeasonsFragment}
  ${MediaFragment}
  ${SourcesFragment}
  ${TracksFragment}
  ${RatingsFragment}
`;

export default ProgramsQuery;
