import styled from 'styled-components';
import { VideoHeadingInnerStyle } from 'components/VideoHeading/VideoHeadingLayout/VideoHeadingLayout.style';
import { UnderlineStyle } from 'components/Tabs/Tabs.style';
import { SkeletonVideoItemWrapperStyle } from '../SkeletonElement/SkeletonElement.style';
import { LayoutStyle } from '../Layout/Layout.style';
import { VideoItemStyle } from '../VideoItem/VideoItem.style';
import { Heading1 } from '../Text/Text.style';
import { TileListStyle } from '../Lists/TileList/TileList.style';

export const FormatLightStyleWrapperStyle = styled(LayoutStyle)<{ isHeaderHidden?: boolean }>`
  &:first-child {
    padding-top: 0;
  }

  ${VideoHeadingInnerStyle} {
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      transition: transform ${props => props.theme.transitions.easeIn};
      transform: ${props => (props.isHeaderHidden ? 'translateY(-56px)' : 'none')};
    }
  }
`;

export const FormatContentWrapperStyle = styled.div<{ addTopPadding: boolean }>`
  padding: 30px 0;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding: ${props => (props.addTopPadding ? '40px 0' : '0 0 40px 0')};
  }

  ${UnderlineStyle} {
    bottom: 0;
  }

  ${TileListStyle} {
    grid-row-gap: 42px;

    @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
      grid-row-gap: 68px;
    }
  }
`;

export const FormatDropdownMenuWrapperStyle = styled.div`
  position: absolute;
  top: 70px;
  width: 100%;
  padding: 0 16px 16px;
  margin: 24px 0 16px;
  display: inline-block;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    top: 50px;
    width: auto;
    margin: 0 16px 24px 0;
    padding: inherit;
    right: 8px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    margin: 0 24px 40px 0;
    top: 0;
    right: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgGrid}) {
    margin: 8px 80px 40px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMax}) {
    margin: 8px 0 0;
  }
`;

export const FormatSubmenuWrapperStyle = styled.div`
  position: relative;
  z-index: 2;
  justify-content: space-between;
  padding-top: 22px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    padding-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMax}) {
    max-width: 1760px;
    margin: auto;
  }

  @media (max-height: ${props => props.theme.breakpoints.smMax}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) and (orientation: landscape) {
    padding-top: 32px;
  }
`;

export const FormatStyle = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.mdMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      flex-direction: column;
    }
  }
`;

export const FormatOverviewStyle = styled.div`
  ${Heading1} {
    margin-bottom: 0;
  }

  ${VideoItemStyle} {
    width: 211px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    ${VideoItemStyle} {
      width: 229px;
    }

    ${Heading1} {
      margin-bottom: 8px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    ${VideoItemStyle} {
      width: 302px;
    }

    ${Heading1} {
      margin-bottom: 14px;
    }
  }
`;

export const FormatOverviewEpisodesStyle = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 5px;
  }
`;

export const FormatOverviewClipsStyle = styled.div`
  margin-top: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 42px;
  }
`;

export const FormatCommonFallbackWrapperStyle = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 120px;
  }
`;
