import styled from 'styled-components';
import { DescriptionStyle } from 'components/VideoItem/VideoItemDescription/VideoItemDescription.style';
import { Heading4 } from 'components/Text/Text.style';
import {
  SkeletonProgramItemTitleStyle,
  SkeletonTitleWrapperStyle,
  SkeletonVideoItemTextWrapperStyle,
  SkeletonVideoItemWrapperStyle
} from 'components/SkeletonElement/SkeletonElement.style';
import Colors from '../../../styles/colors';

export const FormatVideoItemWrapperStyle = styled.div<{ hasSeasonSelector?: boolean }>`
  display: block;
  margin-top: ${props => (props.hasSeasonSelector ? '52px' : '0')};

  > * {
    margin-bottom: 16px;
  }

  ${DescriptionStyle} {
    a div {
      font-size: 11px;
      font-weight: bold;
      color: ${Colors.brownGrey};

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        font-size: 12px;
      }
    }

    a ${Heading4} {
      font-size: 16px;
      font-weight: bold;

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        font-weight: normal;
      }
    }

    a + a ${Heading4} {
      font-size: 14px;
      font-weight: normal;
      color: ${Colors.white60};
      margin-top: 2px;

      @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
        font-size: 16px;
        color: ${Colors.white};
        margin-top: 0;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        top: 0;
      }

      div:first-child {
        bottom: -3px;
      }
    }

    ${SkeletonVideoItemWrapperStyle} + ${SkeletonTitleWrapperStyle} {
      margin-top: 75px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-template-columns: repeat(auto-fill, minmax(187px, 1fr));
    grid-gap: 16px;

    ${SkeletonVideoItemWrapperStyle} {
      div:last-child {
        bottom: 0;
      }
    }

    ${SkeletonVideoItemTextWrapperStyle} {
      div:last-child {
        bottom: -15px;
      }
    }

    ${SkeletonProgramItemTitleStyle} {
      margin-top: 5px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 47px 16px;
    margin-top: 0;
    > * {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.mdMid}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgMid}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const MessageEmptyVideoListStyle = styled.div`
  width: 288px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 10px;
  color: ${Colors.white};
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  letter-spacing: normal;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    width: 100%;
    padding-top: 45px;
    font-size: 18px;
    line-height: 24px;
  }
`;
