import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import AvailableSeasonsQuery, { AVAILABLE_SEASON_PREFIX } from 'queries/programs/availableSeasons.query';
import Dropdown from 'components/Dropdown';
import { trackClick } from 'lib/tealium';
import { FETCH_POLICY, TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from '../../constants';
import { FormatDropdownMenuWrapperStyle } from '../Format/Format.style';
import { SortContainerStyle } from '../Home/Home.style';

const SeasonSelector: React.FC<ISeasonSelectorProps> = ({
  seasonsArray,
  defaultSeason,
  onSeasonChange,
  setAvailableSeasons
}) => {
  const [options, setOptions] = useState(seasonsArray);

  useQuery(AvailableSeasonsQuery(seasonsArray), {
    skip: !seasonsArray?.length,
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
    onCompleted: data => {
      const nextOptions = seasonsArray.map((season, index) => {
        const { totalResults } = data[`${AVAILABLE_SEASON_PREFIX}${index}`] || {};
        return {
          ...season,
          isDisabled: totalResults === 0
        };
      });
      setOptions(nextOptions);
      setAvailableSeasons(nextOptions.filter(season => !season.isDisabled) as ISeriesSeasonAvailability[]);

      // Validate if current season has items
      if (nextOptions[defaultSeason].isDisabled) {
        const availableSeasonIndex: number = nextOptions.findIndex(option => !option.isDisabled);
        availableSeasonIndex && onSeasonChange(availableSeasonIndex);
      }
    }
  });

  const handleChange = useCallback((value: string) => {
    const activeSeasonIndex = seasonsArray.findIndex(season => season.value === value);
    trackClick({
      name: TEALIUM_EVENT_NAME.MENU_CLICK,
      category: TEALIUM_EVENT_CATEGORY.MENU,
      label: `seizoen | ${options[activeSeasonIndex].label}`
    });
    onSeasonChange(activeSeasonIndex);
  }, []);

  return (
    <FormatDropdownMenuWrapperStyle>
      <SortContainerStyle>
        <Dropdown
          key={defaultSeason}
          options={options}
          callback={handleChange}
          defaultValue={options[defaultSeason || 0]}
          showActiveIcon
        />
      </SortContainerStyle>
    </FormatDropdownMenuWrapperStyle>
  );
};
export default SeasonSelector;
