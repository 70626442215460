import { gql } from '@apollo/client';
import { PROGRAM } from '../../constants';

const EMPTY_QUERY = `{
  programs (limit: 1) {
    totalResults
  }
}`;

export const AVAILABLE_SEASON_PREFIX = 'program_';

const AvailableSeasonsQuery = (seasons: IDropdownOptions[]) => {
  if (!seasons?.length) {
    return gql(EMPTY_QUERY);
  }
  return gql`query AvailableSeasons{
    ${seasons.map(
      (
        season,
        index
      ) => `${AVAILABLE_SEASON_PREFIX}${index}: programs(tvSeasonId: "${season.value}", skip: 0, limit: 1, programTypes: ${PROGRAM.EPISODE}) {
      totalResults
    }`
    )}
  }`;
};

export default AvailableSeasonsQuery;
