import React, { useState, useEffect, ReactElement } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import FormatVideoList from 'components/Format/FormatVideoList';
import ErrorMessage from 'components/ErrorMessage';
import ErrorBoundary from 'components/ErrorBoundary';
import CommonFallback from 'components/CommonFallback';
import {
  FormatLightStyleWrapperStyle,
  FormatContentWrapperStyle,
  FormatSubmenuWrapperStyle,
  FormatStyle,
  FormatOverviewStyle,
  FormatOverviewEpisodesStyle,
  FormatOverviewClipsStyle,
  FormatCommonFallbackWrapperStyle
} from 'components/Format/Format.style';
import { DarkStyle } from 'components/Home/Home.style';
import FormatHeading from 'components/FormatHeading';
import PromoImageHeadingFallback from 'components/PromoImageHeading/PromoImageHeadingFallback';
import VideoHeading from 'components/VideoHeading';
import FeaturedPrograms from 'components/FeaturedPrograms';
import Ad from 'components/Ad';
import { Heading2 } from 'components/Text/Text.style';
import Carousel from 'components/Lists/Carousel';
import {
  FormatVideoItemWrapperStyle,
  MessageEmptyVideoListStyle
} from 'components/Format/FormatVideoList/FormatVideoList.style';
import SeasonSelector from 'components/SeasonSelector';
import VideoList from 'components/VideoList';
import EditorialOverview from 'components/EditorialOverview';
import { Tabs, Tab } from 'components/Tabs';
import TileList from 'components/Lists/TileList';
import PortraitItem from 'components/PortraitItem';
import PortraitItemFallback from 'components/PortraitItem/PortraitItemFallback';
import CustomGradient from 'components/CustomGradient';
import TrendingProgramsQuery from 'queries/featuredPrograms/trendingProgramsQuery.query';
import OverviewModel from 'models/overview.model';
import OverviewItemModel from 'models/overviewItem.model';
import EpisodeModel from 'models/episode.program.model';
import ClipModel from 'models/clip.program.model';
import VideoModel from 'models/video.model';
import SeriesModel from 'models/series.model';
import { isServer } from 'lib/isBrowser';
import { fetchFormatPageData } from 'lib/helpers/getFormatPageData';
import { IGetInitialProps } from 'typings/IGetInitialProps';
import { IWithInitialProps } from 'typings/IWithInitialProps';
import { IFormatDetailsProps } from 'typings/IFormatDetailsProps';
import { SkeletonPromoHeading } from 'components/SkeletonElement/SkeletonElement';
import ProgramsQuery from 'queries/programs/programsQuery.query';
import { handle500 } from 'lib/pageHelpers';
import VideoUnavailable from 'components/PlayerContainer/VideoUnavailable/VideoUnavailable';
import {
  FEATURED_PROGRAMS,
  CLIENT,
  PROGRAM,
  FALLBACK_UI_LABELS,
  TAB_NAMES,
  COLLECTION_TYPE,
  PROGRAM_SORT_KEY,
  PLAYER_MESSAGES
} from '../../../src/client/constants';
import { Grid, GridElement } from '../../../src/client/styles/grid';

const VideoListTab = ({
  programs,
  tabName,
  activeVideoGuid
}: {
  programs: EpisodeModel[] | ClipModel[];
  tabName: string;
  activeVideoGuid: string;
}): ReactElement => (
  <>
    <Grid>
      <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
        <Heading2>{tabName}</Heading2>
      </GridElement>
    </Grid>
    <Carousel>
      <VideoList programList={programs} activeVideoGuid={activeVideoGuid} />
    </Carousel>
  </>
);

const FormatDetails: IWithInitialProps<IFormatDetailsProps> = ({ client, viewMode, formatDataInitial }) => {
  const router = useRouter();

  const { videoId, formatSlug } = router.query;
  const [shouldShowSeasonSelector, setShouldShowSeasonSelector] = useState(
    formatDataInitial?.initialTab === PROGRAM.EPISODE
  );
  const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false);
  const [availableSeasons, setAvailableSeasons] = useState<ISeriesSeasonAvailability[]>();
  const [activeSeasonIndex, setActiveSeasonIndex] = useState<number | null>(() => {
    const { video, format } = formatDataInitial || {};
    if (!video && !format?.seasonList) return null;

    if (!video || video.type !== PROGRAM.EPISODE || !video.seasonNumber || !format) return 0;
    const activeSeasonIndex = format.seriesTvSeasons.findIndex(season => season.seasonNumber === video.seasonNumber);

    return activeSeasonIndex >= 0 ? activeSeasonIndex : 0;
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formatData, setformatData] = useState<
    | {
        video?: VideoModel;
        format?: SeriesModel;
        overview?: { clips: ClipModel[]; episodes: EpisodeModel[] };
        editorialOverview?: OverviewModel;
        initialTab?: string;
        error?: string;
      }
    | undefined
  >(formatDataInitial);

  useEffect(() => {
    if (formatData?.format && formatData?.overview) {
      updateActiveSeason();
    }
  }, [formatSlug, videoId, formatData]);

  useEffect(() => {
    if (!formatDataInitial?.format) {
      setIsLoading(true);
      fetchFormatPageData(client, { query: router.query, push: router.push }, false).then(formatPageData => {
        setformatData(formatPageData);
        setIsLoading(false);
      });
    }
  }, [router.asPath]);

  useEffect(() => {
    let ScrollPosition: number = window.scrollY;
    const handleScroll = (): void => {
      setIsHeaderHidden(window.scrollY > 80 && window.scrollY >= ScrollPosition);
      ScrollPosition = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSeasonChange = (activeSeasonIndex: number) => {
    setActiveSeasonIndex(activeSeasonIndex);
  };

  const updateActiveSeason = (): void => {
    const { video, format } = formatData || {};
    const activeSeasonIndex =
      video?.type === PROGRAM.EPISODE && video.seasonNumber && format
        ? format.seriesTvSeasons.findIndex(season => season.seasonNumber === video.seasonNumber)
        : 0;

    setActiveSeasonIndex(activeSeasonIndex >= 0 ? activeSeasonIndex : 0);
  };

  const handleTabChange = (tabName: string) => {
    setShouldShowSeasonSelector(tabName === PROGRAM.EPISODE);
  };

  const Video: EpisodeModel | ClipModel | undefined = formatData?.overview
    ? [...formatData.overview.episodes, ...formatData.overview.clips].find(item => item?.media?.[0]?.availabilityState)
    : undefined;

  const { hideOverviewTab, hideEpisodeTab, hideClipTab, renameOverviewTab, renameEpisodeTab, renameClipTab } =
    formatData?.editorialOverview?.config || {};

  const OverviewTabName = renameOverviewTab || TAB_NAMES.OVERZICHT;
  const EpisodeTabName = renameEpisodeTab || TAB_NAMES.AFLEVERINGEN;
  const ClipTabName = renameClipTab || TAB_NAMES.CLIPS;

  const { format, video, overview, editorialOverview, initialTab } = formatData || {};

  if (formatData?.error) {
    return <ErrorMessage marginTop="100px" />;
  }

  if ((isLoading || !formatData?.format) && !router.query.videoId) {
    return <SkeletonPromoHeading />;
  }

  return (
    <>
      <Head>
        {format &&
          (video && videoId ? (
            <>
              <title>{video.programFullTitle}</title>
              <meta name="og:title" content={video.programFullTitle} />
              <meta name="og:type" content="video:tv_show" />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(video.structuredData)
                }}
              />
              <meta name="description" content={video.description || format.shortDescription} />
              <meta name="og:description" content={video.description || format.shortDescription} />
              <meta name="og:url" content={`https://www.kijk.nl${router.asPath}`} />
              <meta name="og:image" content={video.image || format.image} />
            </>
          ) : (
            <>
              <title>{`${format.title} gemist? Kijk terug op KIJK.nl`}</title>
              <meta name="og:title" content={format.title} />
            </>
          ))}
      </Head>
      <FormatStyle data-testid="format">
        <CustomGradient slug={`${router.query.formatSlug}`} />
        <ErrorBoundary
          onError={_ => (
            <FormatCommonFallbackWrapperStyle>
              <CommonFallback message={FALLBACK_UI_LABELS.FORMAT} />
            </FormatCommonFallbackWrapperStyle>
          )}
        >
          <FormatLightStyleWrapperStyle isHeaderHidden={isHeaderHidden} data-testid="formatLightStyleWrapperStyle">
            {videoId ? (
              <ErrorBoundary onError={_ => <VideoUnavailable {...PLAYER_MESSAGES.NO_PLAYABLE_SOURCES_FOUND} />}>
                <VideoHeading
                  viewMode={viewMode}
                  client={client}
                  editorialItems={editorialOverview?.items}
                  videoType={video?.type}
                  videoGuid={`${videoId}`}
                  seasonId={video?.tvSeasonId}
                  availableSeasons={availableSeasons}
                />
              </ErrorBoundary>
            ) : (
              <ErrorBoundary onError={_ => <PromoImageHeadingFallback />}>
                <FormatHeading
                  {...(format as SeriesModel)}
                  videoMetadata={Video?.metadata}
                  ctaLabel={Video?.ctaLabel || ''}
                  path={Video?.path || ''}
                  viewMode={viewMode}
                />
              </ErrorBoundary>
            )}
            {format && format.slug === formatSlug && (
              <FormatContentWrapperStyle data-testid="formatContentWrapperStyle" addTopPadding={!!videoId}>
                <ErrorBoundary onError={_ => <CommonFallback message={FALLBACK_UI_LABELS.TAB_NAV} />}>
                  <FormatSubmenuWrapperStyle>
                    {shouldShowSeasonSelector && (
                      <SeasonSelector
                        seasonsArray={format.seasonList}
                        defaultSeason={
                          format.seasonList?.length &&
                          activeSeasonIndex !== null &&
                          format.seasonList[activeSeasonIndex]
                            ? activeSeasonIndex
                            : 0
                        }
                        onSeasonChange={handleSeasonChange}
                        setAvailableSeasons={setAvailableSeasons}
                      />
                    )}
                  </FormatSubmenuWrapperStyle>
                  {!overview?.episodes.length && !overview?.clips.length && !editorialOverview?.items.length ? (
                    <CommonFallback message={FALLBACK_UI_LABELS.AVAILABLE_SOON} />
                  ) : (
                    <Tabs
                      key={`${format.guid}-${video?.guid}-${initialTab}`}
                      initialTab={initialTab}
                      type="format"
                      showSeasonSelector={shouldShowSeasonSelector}
                      formatTitle={format.title}
                      onTabChange={handleTabChange}
                    >
                      {/* // Overview Tab */}
                      {!hideOverviewTab &&
                      ((overview?.episodes.length && overview?.clips.length) || editorialOverview) ? (
                        <Tab name="overview" title={OverviewTabName}>
                          <>
                            <FormatOverviewStyle>
                              {!hideEpisodeTab && overview?.episodes.length ? (
                                <FormatOverviewEpisodesStyle>
                                  <VideoListTab
                                    programs={overview.episodes}
                                    tabName={EpisodeTabName}
                                    activeVideoGuid={videoId as string}
                                  />
                                </FormatOverviewEpisodesStyle>
                              ) : null}
                              {!hideClipTab && overview?.clips.length ? (
                                <FormatOverviewClipsStyle>
                                  <VideoListTab
                                    programs={overview.clips}
                                    tabName={ClipTabName}
                                    activeVideoGuid={videoId as string}
                                  />
                                </FormatOverviewClipsStyle>
                              ) : null}
                            </FormatOverviewStyle>
                            {editorialOverview && (
                              <FormatOverviewStyle data-testid="editorialRowWrapper">
                                <EditorialOverview
                                  editorialOverviewItems={editorialOverview.items}
                                  activeVideoGuid={videoId as string}
                                  viewMode={viewMode}
                                />
                              </FormatOverviewStyle>
                            )}
                          </>
                        </Tab>
                      ) : null}
                      {/* Episode Tab */}
                      {!hideEpisodeTab && overview?.episodes.length ? (
                        <Tab name={PROGRAM.EPISODE} title={EpisodeTabName}>
                          {format.seasonList.length > 0 ? (
                            <Grid>
                              <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                                <ErrorBoundary
                                  onError={_ => <CommonFallback message={FALLBACK_UI_LABELS.FORMAT_VIDEO_LIST} />}
                                >
                                  <FormatVideoList
                                    type={initialTab === PROGRAM.CLIP ? PROGRAM.CLIP : PROGRAM.EPISODE}
                                    activeSeason={
                                      (activeSeasonIndex !== null && format.seasonList[activeSeasonIndex]) || undefined
                                    }
                                    seriesTitle={format.title}
                                    router={router}
                                    isFormatDetails={!!videoId}
                                    programId={format.id}
                                    swimlaneTitle={EpisodeTabName}
                                  />
                                </ErrorBoundary>
                              </GridElement>
                            </Grid>
                          ) : null}
                        </Tab>
                      ) : null}
                      {/* Clip Tab */}
                      {!hideClipTab && overview?.clips.length ? (
                        <Tab name={PROGRAM.CLIP} title={ClipTabName}>
                          <Grid>
                            <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                              <ErrorBoundary
                                onError={_ => <CommonFallback message={FALLBACK_UI_LABELS.FORMAT_VIDEO_LIST} />}
                              >
                                <FormatVideoList
                                  type={PROGRAM.CLIP}
                                  seriesTitle={format.title}
                                  router={router}
                                  isFormatDetails={!!videoId}
                                  programId={format.id}
                                  swimlaneTitle={ClipTabName}
                                />
                              </ErrorBoundary>
                            </GridElement>
                          </Grid>
                        </Tab>
                      ) : null}
                      {/* Editorial Tabs */}
                      {editorialOverview?.editorialTabs?.length
                        ? editorialOverview.editorialTabs.map(editorialTab => (
                            <Tab key={editorialTab.slug} name={editorialTab.slug} title={editorialTab.title}>
                              {editorialTab.overviewItem && editorialTab.overviewItem.programs?.length ? (
                                <>
                                  {(editorialTab.type === COLLECTION_TYPE.EPISODE_COLLECTION ||
                                    editorialTab.type === COLLECTION_TYPE.CLIP_COLLECTION) &&
                                    COLLECTION_TYPE.CLIP_COLLECTION && (
                                      <Grid data-testid="formatEditorialList">
                                        <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                                          <FormatVideoItemWrapperStyle>
                                            <VideoList
                                              programList={editorialTab.overviewItem.programs as VideoModel[]}
                                              activeVideoGuid={videoId as string}
                                              smLandscape
                                              isFormatPage
                                            />
                                          </FormatVideoItemWrapperStyle>
                                        </GridElement>
                                      </Grid>
                                    )}

                                  {(editorialTab.type === COLLECTION_TYPE.SERIES_COLLECTION ||
                                    editorialTab.type === COLLECTION_TYPE.MOVIE_COLLECTION) && (
                                    <Grid data-testid="formatEditorialList">
                                      <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                                        <TileList>
                                          {(editorialTab.overviewItem as OverviewItemModel)?.programs.map(program => (
                                            <div key={program.guid as string}>
                                              <ErrorBoundary onError={_ => <PortraitItemFallback />}>
                                                <PortraitItem program={program} />
                                              </ErrorBoundary>
                                            </div>
                                          ))}
                                        </TileList>
                                      </GridElement>
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <MessageEmptyVideoListStyle data-testid="messageEmptyVideoList">
                                  Oeps… er ging iets verkeerd. De benodigde content kon niet worden opgehaald!
                                </MessageEmptyVideoListStyle>
                              )}
                            </Tab>
                          ))
                        : null}
                    </Tabs>
                  )}
                </ErrorBoundary>
              </FormatContentWrapperStyle>
            )}
            <Ad id={viewMode === CLIENT.MOBILE ? 'm-r1' : 'h1'} />
          </FormatLightStyleWrapperStyle>
        </ErrorBoundary>
        <DarkStyle data-testid="darkStyle">
          <FeaturedPrograms query={TrendingProgramsQuery} title={'Populair'} type={FEATURED_PROGRAMS.POPULAR} />
        </DarkStyle>
      </FormatStyle>
    </>
  );
};

FormatDetails.getInitialProps = async ({ apolloClient, query }: IGetInitialProps): Promise<any> => {
  if (!isServer) {
    return;
  }

  const formatPageData = await fetchFormatPageData(apolloClient, { query }, true);
  // handle 301 and 404 responses
  if (formatPageData.statusCode) return formatPageData;

  const videoType = formatPageData.video?.type;
  const variables: { [key: string]: number | string } = { skip: 0, limit: 12 };
  const activeSeason = formatPageData.format?.seriesTvSeasons.find(
    season => season.seasonNumber === formatPageData.video?.seasonNumber
  );

  if (videoType === PROGRAM.EPISODE && activeSeason) {
    variables.tvSeasonId = activeSeason.id.split('/').pop() as string;
    variables.programTypes = PROGRAM.EPISODE;
  } else if (videoType === PROGRAM.CLIP && formatPageData.format) {
    variables.seriesId = formatPageData.format.id;
    variables.sort = PROGRAM_SORT_KEY.PUBLICATIONDATETIME;
    variables.programTypes = PROGRAM.CLIP;
  } else if (formatPageData.format?.type === PROGRAM.SERIES && formatPageData.format?.seriesTvSeasons?.length) {
    variables.tvSeasonId = formatPageData.format.seriesTvSeasons[0].id.split('/').pop() as string;
    variables.programTypes = PROGRAM.EPISODE;
  } else {
    return;
  }

  try {
    await apolloClient.query({
      query: ProgramsQuery,
      variables
    });
  } catch (e) {
    return handle500(`Programs query failed - ${e}`);
  }

  return { formatDataInitial: formatPageData };
};

export default FormatDetails;
