import React, { FunctionComponent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import VideoList from 'components/VideoList';
import { SkeletonVideoItemFormatIndex } from 'components/SkeletonElement/SkeletonElement';
import ErrorMessage from 'components/ErrorMessage';
import ProgramsQuery from 'queries/programs/programsQuery.query';
import EpisodeModel from 'models/episode.program.model';
import ClipModel from 'models/clip.program.model';
import VideoModel from 'models/video.model';
import { useScreenDimensions } from 'lib/useScreenDimensions';
import { IFormatVideoListProps } from 'typings/IFormatVideoListProps';
import ShowMoreButton from 'components/ShowMoreButton/ShowMoreButton';
import { FormatVideoItemWrapperStyle, MessageEmptyVideoListStyle } from './FormatVideoList.style';
import { GridElement } from '../../../styles/grid';
import { PROGRAM, PROGRAM_SORT_KEY, FIXED_COUNT, BREAKPOINTS } from '../../../constants';
import { EMPTY_VIDEO_LIST_MESSAGE } from '../../../constants/videoList';

const MessageEmptyVideoList: FunctionComponent<{ type: string }> = ({ type }) =>
  type === PROGRAM.CLIP ? (
    <MessageEmptyVideoListStyle>{EMPTY_VIDEO_LIST_MESSAGE.CLIPS}</MessageEmptyVideoListStyle>
  ) : (
    <MessageEmptyVideoListStyle data-testid="messageEmptyVideoList">
      {EMPTY_VIDEO_LIST_MESSAGE.EPISODES}
    </MessageEmptyVideoListStyle>
  );

const FormatVideoList = ({
  type,
  swimlaneTitle,
  activeSeason,
  programId,
  router,
  seriesTitle
}: IFormatVideoListProps): JSX.Element | null => {
  const [programs, setPrograms] = useState<IProgramGraphql[]>([]);
  const [videos, setVideos] = useState<VideoModel[]>([]);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [screenWidth] = useScreenDimensions();
  const optionalItemSkeleton = React.useMemo(() => {
    const getSkeletonAmount = (): number => {
      const columnGap = screenWidth >= BREAKPOINTS.lgMin ? 24 : 16;
      const minWidth = screenWidth > BREAKPOINTS.smMax ? 210 : 100;

      return (
        Array.from({ length: 6 }, (_, i) => i + 1)
          .reverse()
          .find(item => item * minWidth + (item - 1) * columnGap <= screenWidth) || 1
      );
    };

    return getSkeletonAmount();
  }, [screenWidth]);

  const variables: { [key: string]: number | string } = {
    programTypes: type,
    skip: 0,
    limit: FIXED_COUNT.FORMAT_VIDEO_LIST_LIMIT
  };

  if (type === PROGRAM.EPISODE) {
    variables.tvSeasonId = activeSeason?.value || '';
  }

  if (type === PROGRAM.CLIP) {
    variables.seriesId = programId;
    variables.sort = PROGRAM_SORT_KEY.PUBLICATIONDATETIME;
  }

  const { data, error, loading, fetchMore } = useQuery<{
    programs: { totalResults: number; items: IProgramGraphql[] };
  }>(ProgramsQuery, {
    variables,
    skip: type === PROGRAM.EPISODE && !activeSeason?.value,
    onCompleted: data => {
      const Model = type === PROGRAM.EPISODE ? EpisodeModel : ClipModel;
      setPrograms(data.programs?.items || []);
      const items = data.programs?.items
        .map(
          (video, index) =>
            new Model(video, { showFormatTitle: false, collectionTitle: swimlaneTitle, position: index + 1 })
        )
        .filter(video => video.isValid);
      setVideos(items);
    }
  });
  const totalResults = data?.programs.totalResults || 0;
  const numberOfPrograms = programs.length;

  const handleShowMoreClick = useCallback((): void => {
    setFetchingMore(true);
    fetchMore({
      variables: {
        skip: numberOfPrograms
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchingMore(false);
        if (!fetchMoreResult) return prev;

        const Model = type === PROGRAM.EPISODE ? EpisodeModel : ClipModel;
        const items = fetchMoreResult.programs?.items
          .map(video => new Model(video, { showFormatTitle: false, collectionTitle: swimlaneTitle }))
          .filter(video => video.isValid);
        setPrograms([...programs, ...fetchMoreResult.programs?.items]);

        const allVideos = [...videos, ...items];
        allVideos.forEach((video, index) => {
          video.eventValues.value = index + 1;
        });
        setVideos(allVideos);

        return {
          programs: {
            items: [...prev.programs.items, ...fetchMoreResult.programs.items],
            totalResults: prev.programs.totalResults
          }
        };
      }
    });
  }, [videos, numberOfPrograms]);

  if (!activeSeason && type !== PROGRAM.CLIP) {
    return null;
  }

  if (loading && !fetchingMore) {
    return (
      <SkeletonVideoItemFormatIndex noOfItems={optionalItemSkeleton} hasSeasonSelector={type === PROGRAM.EPISODE} />
    );
  }
  if (error) return <ErrorMessage />;

  if (!fetchingMore && !videos.length) {
    return <MessageEmptyVideoList type={type} />;
  }

  return (
    <div data-testid="formatVideoList">
      <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
        <FormatVideoItemWrapperStyle hasSeasonSelector={type === PROGRAM.EPISODE}>
          <VideoList
            activeVideoGuid={router.query.videoId || ''}
            programList={videos}
            seriesTitle={seriesTitle}
            smLandscape
            isFormatPage
          />
        </FormatVideoItemWrapperStyle>
      </GridElement>
      <ShowMoreButton
        isVisible={totalResults > numberOfPrograms}
        isDisabled={fetchingMore}
        type={type === PROGRAM.EPISODE ? 'afleveringen' : 'fragmenten'}
        handleClick={handleShowMoreClick}
      />
    </div>
  );
};

export default FormatVideoList;
